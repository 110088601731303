import { Dayjs } from "dayjs";
import { EvaluationGridTypeEnum } from "./evaluationGrids";

type MinMaxFilter = {
  min: number;
  max: number;
};

export type EvaluationItemFilter =
  | BinaryItemFilter
  | MultipleLevelsItemFilter
  | ComposedItemFilter;

export type BinaryItemFilter = {
  item_type: EvaluationGridTypeEnum.binary;
  item_id: string;
  not_applicable: boolean;
  followed_instructions?: boolean;
};

export type MultipleLevelsItemFilter = {
  item_type: EvaluationGridTypeEnum.multiple_levels;
  item_id: string;
  not_applicable: boolean;
  level?: string;
};

export type ComposedItemFilter = {
  item_type: EvaluationGridTypeEnum.composed;
  item_id: string;
  level: string;
};

export type DashboardFilters = {
  timeRange: {
    start: Dayjs;
    end: Dayjs;
  };
  organisations?: string[];
  campaigns?: string[];
  agents?: string[];
  metadata?: {
    [key: string]: string[];
  };
  score?: MinMaxFilter;

  // campaign specific filters
  conversationsMetadata?: {
    [key: string]: string[];
  };
  topics?: string[];
  evalItems?: EvaluationItemFilter[];
};

export class FilterStore {
  public timeRange: {
    start: Dayjs;
    end: Dayjs;
  };

  public organisations?: string[];
  public campaigns?: string[];
  public agents?: string[];
  public metadata?: {
    [key: string]: string[];
  };
  public score?: MinMaxFilter;
  public conversationsMetadata: {
    [campaignId: string]: {
      [key: string]: string[];
    };
  } = {};
  public topics: { [campaignId: string]: string[] } = {};
  public evalItems: {
    [campaignId: string]: EvaluationItemFilter[];
  } = {};

  constructor(
    filters: Partial<DashboardFilters> & {
      timeRange: {
        start: Dayjs;
        end: Dayjs;
      };
    },
    conversationsMetadata?: {
      [campaignId: string]: {
        [key: string]: string[];
      };
    },
    topics?: { [campaignId: string]: string[] },
    evalItems?: {
      [campaignId: string]: EvaluationItemFilter[];
    }
  ) {
    this.timeRange = filters.timeRange;
    this.organisations = filters.organisations;
    this.campaigns = filters.campaigns;
    this.agents = filters.agents;
    this.metadata = filters.metadata;
    this.score = filters.score;

    this.conversationsMetadata = {
      ...conversationsMetadata,
    };

    this.topics = {
      ...topics,
    };

    this.evalItems = {
      ...evalItems,
    };
  }

  getFilters(campaignId?: string): DashboardFilters {
    return {
      timeRange: this.timeRange,
      organisations: this.organisations,
      campaigns: this.campaigns,
      agents: this.agents,
      metadata: this.metadata,
      score: this.score,
      conversationsMetadata: campaignId
        ? this.conversationsMetadata[campaignId]
        : {},
      topics: campaignId ? this.topics[campaignId] : [],
      evalItems: campaignId ? this.evalItems[campaignId] : [],
    };
  }

  cloneFrom(
    filters?: Partial<DashboardFilters>,
    conversationsMetadata?: {
      [campaignId: string]: {
        [key: string]: string[];
      };
    },
    topics?: { [campaignId: string]: string[] },
    evalItems?: {
      [campaignId: string]: EvaluationItemFilter[];
    }
  ) {
    conversationsMetadata = {
      ...this.conversationsMetadata,
      ...conversationsMetadata,
    };

    topics = {
      ...this.topics,
      ...topics,
    };

    evalItems = {
      ...this.evalItems,
      ...evalItems,
    };

    return new FilterStore(
      { ...this.getFilters(), ...filters },
      conversationsMetadata,
      topics,
      evalItems
    );
  }
}
